import React, { useState } from "react";
import DesktopSidebar from "./componants/digisignDetails/DesktopSidebar";
import MobileBottomNav from "./componants/digisignDetails/MobileBottomNav";
import useWindowSize from "./componants/digisignDetails/useWindowSize";
import TabContent from "./TabContent";
import  Headers from "../../pages/Header";
function NewLayout() {
  const { width } = useWindowSize();
  const [activeTab, setActiveTab] = useState("Inbox"); // Initialize the active tab

  return (
    <>
     <Headers/>
      <div className="flex min-h-screen bg-[#0D0B35]">
        {/* Sidebar */}
        {width >= 768 ? (
          <DesktopSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        ) : (
          <MobileBottomNav activeTab={activeTab} setActiveTab={setActiveTab} />
        )}

        {/* Main Content */}
        <main
          className={`ml-64 w-full bg-[#0D0B35] p-8 side_content ${width < 768 ? "ml-0" : ""
            }`}
        >
          <TabContent activeTab={activeTab} />
        </main>
      </div>
    </>
  );
}

export default NewLayout;
