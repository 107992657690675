import React, {useState, useEffect} from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import {getMethod} from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getLawerDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAllAdvocates,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
    } catch (error) {
    }
  };

  const goToProfile = async (id) => {
    try {
      navigate("/profile?" + id);
    } catch (error) {
    }
  };

  // getAllAdvocates

  return (
    <>
      <Header />
      <main className="pt-[5.5rem] lg:pt-24">
        <div className="container">
          <div class="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            <h2 class="inline">Legal Panel</h2>
          </div>
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
           
        {
            
            lawLoader == true ?  

              <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
                <img
                  width="60"
                  height="60"
                  class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                  src={require("../img/loadinggif.gif")}
                ></img>
              </div>
          
          : 
          <>
            {
              allAdvocate.length > 0 ?

              allAdvocate.map((item, i) => {
                return(
                //   <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
                //   <figure class="mr-4 shrink-0" style={{"cursor":"pointer"}} >
                //     <a
                //       class="relative block"
                //       onClick={() => goToProfile(item._id)}
                //     >
                //       {
                //         item.prfileIMG == "" || item.prfileIMG == null ? 
                //         <img
                //         src={require("../img/advocateImage-removebg-preview.png")}
                //         width="100px"
                //         height="100px"
                //         alt="avatar 1"
                //         class="rounded-2lg lawe_img"
                //         loading="lazy"
                //       /> : 
                //       <img
                //       src={item.prfileIMG }
                //       width="100px"
                //       height="100px"
                //       alt="avatar 1"
                //       class="rounded-2lg lawe_img"
                //       loading="lazy"
                //     />
                //       }
                  
                     
                //     </a>
                //   </figure>

                //   <div className="lawer_info">
                //       <h4>{ item && item.fullName}</h4>
                //       <p><span>Experience:</span> { item && item.Experience} years</p>
      
                //       <p><i class="ri-map-pin-fill"></i> {item && item.city},  {" "} {item && item.state}, {" "} { item && item.country}</p>
                //       <a onClick={() => goToProfile(item._id)}>View</a>
                //   </div>
             
                // </div>
                <div className="card_layer posiuion">
                <div className="lawer_imga">
                {item && item.prfileIMG == "" ||
                      item && item.prfileIMG == null ? (
                        <img
                          src={require("../img/advocateImage-removebg-preview.png")}
                          width="100px"
                          height="100px"
                          alt="avatar 1"
                          class="rounded-2lg"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          src={item && item.prfileIMG}
                          width="100px"
                          height="100px"
                          alt="avatar 1"
                          class="rounded-2lg"
                          loading="lazy"
                        />
                      )}
                </div>

                    <div className="lawer_info">
                      <h4>{ item && item.fullName}</h4>
                      {/* <p><span>Enroll:</span>{ item && item.enrollmentNumber}</p> */}
                      <p><span>Experience:</span> { item && item.Experience} years</p>
                      <p><span>expertise:</span> Civil</p>

                      <p><i class="ri-map-pin-fill"></i> {item && item.city},  {" "} {item && item.state}, {" "} { item && item.country}</p>
                      {/* <a onClick={() => goToProfile(item._id)}>View</a> */}
                    </div>
                    <div className="postion_hover"> <a onClick={() => goToProfile(item._id)}>View</a></div>

                  </div>


                )
              })
              
              : "No Advocates Found!"
            
            }
          </>
        }

            {/* <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div>
            <div class="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
              <figure class="mr-4 shrink-0">
                <a href="/" class="relative block">
                  <img
                    src={require("../img/avatars/avatar_1.jpg")}
                    alt="avatar 1"
                    class="rounded-2lg"
                    loading="lazy"
                  />
                  <div class="absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
                    1
                  </div>
                  <div
                    class="absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                    data-tippy-content="Verified Collection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-[.875rem] w-[.875rem] fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </div>
                </a>
              </figure>
              <div>
                <a href="collection.html" class="block">
                  <span class="font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white">
                    NFT Funny Cat
                  </span>
                </a>
                <span class="text-sm dark:text-jacarta-300">7,080.95 ETH</span>
              </div>
            </div> */}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Home;
