import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Slider from "react-slick";
import useStateRef from "react-usestateref";
import { useNavigate } from "react-router-dom";
function Home() {

  const navigate = useNavigate()
  const [File, setFile] = useState("")
  const [name, setname] = useState("")
  const [Email, setEmail] = useState("")
  const [checked, setchecked, checkedref] = useStateRef(false)
  const onFileChange = (event) => {
    if (event.target.files[0].name.split('.')[1] == 'pdf') {
      setFile(event.target.files[0]);
    } else {
      toast.error("Please upload a PDF file")
    }
  };

  const getvalue = (e) => {
    setchecked(e.target.checked);
  }

  

  const nextAction = () => {
    navigate('/digital_sign')
  }

  return (
    <>
      <section className="py-10 pt-20"> 
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 p-4">
              <div className="bg-[#0D0B35] min-h-screen flex items-start justify-center w-full flex-col justify-start items-start">
                <h2 className="text-left text-white text-3xl font-semibold mb-8">
                  Add Documents
                </h2>
                <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                  <label
                    htmlFor="file-upload"
                    className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center upload_section_code"
                  >
                    {File == "" ?
                      <> 
                      <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                        <span className="text-white text-lg font-medium">
                          Upload Document
                        </span></>
                         : <i class="fa fa-file-pdf-o" style={{ fontSize : "200px"}} aria-hidden="true"></i>                        }
                    <input id="file-upload" type="file" className="hidden" onChange={onFileChange} />
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <h2 className="text-left text-white text-3xl font-semibold mb-8">
                Add Recipients
              </h2>
              <div className="min-h-screen flex items-center justify-center">
                <div className="dark:bg-jacarta-700 w-full rounded-xl p-8 py-10 space-y-6">
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      id="only-signer"
                      onChange={getvalue}
                      className="h-5 w-5 text-[#6c41d6] bg-transparent border-2 border-[#6c41d6] rounded focus:ring-0"
                    />
                    <label
                      htmlFor="only-signer"
                      className="text-white text-lg font-medium"
                    >
                      I am the only signer
                    </label>
                  </div>
                  {checkedref.current == false ?
                  <>
                  <div className="mb-6">
                    <label
                      htmlFor="item-name"
                      className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Name<span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="item-name"
                      name="name"
                      onChange={(e) => setname(e.target.value)}
                      className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter Name"
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="item-email"
                      className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Email<span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      id="item-email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter Email"
                      required
                    />
                  </div>
                  <div className="form_betwine flex justify-end pt-2">
                    <Link
                      className="inline-block rounded bg-accent py-2 px-5 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                      
                    >
                      <i className="ri-user-add-line text-lg"></i>
                      <span>Add recipients</span>
                    </Link>
                  </div>
                  </> : "" }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer with Next Button */}
      <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4">
        <div className="container-lg mx-auto flex justify-end">
          <button
            className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            onClick={nextAction}
          >
            Next
          </button>
        </div>
      </footer>
    </>
  );
}

export default Home;
