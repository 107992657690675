import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
function Aicreatetemp() {

  const navigate = useNavigate()
  const value = {
    State: "",
    Country: "",
    Document: "",
  };
  const [inputValue, setinputValue] = useState(value);
  const [Stateerr, setStateerr] = useState(false);
  const [Countryerr, setCountryerr] = useState(false);
  const [Documenterr, setDocumenterr] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [DownLoader, setDownLoader] = useState(false);
  const [downloadOption, setdownloadOption] = useState(false);
  const [validate, setvalidate] = useState("");
  const [htmlContent, sethtmlContent] = useState("");
  const getValues = (e) => {
    const { name, value } = e.target;
    const formData = { ...inputValue, ...{ [name]: value } };
    setinputValue(formData);
  };
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("state");
  const [selectedCity, setSelectedCity] = useState("city");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ name: firstCountry } = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
      } catch (error) {
        setCountries([]);
      }
    };
    getCountries();
  }, []);
  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstState = "" } = {}] = allStates;
        // setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        // setCities([]);
        setSelectedCity("");
      }
    };
    getStates();
  }, [selectedCountry]);
  const { Document } = inputValue;
  const docxHtmlRef = useRef(null);
  const validation = () => {
    const error = {};
    if (selectedCountry == "") {
      error.Country = "Country is required";
      setCountryerr(true);
    }
    if (selectedState == "") {
      error.State = "State is required";
      setStateerr(true);
    } else {
      setStateerr(false);
    }
    if (inputValue.Document == "") {
      error.Document = "Document is required";
      setDocumenterr(true);
    }
    setvalidate(error);
  };
  const documentDownloader = async () => {
    try {
      if (htmlContent != "") {
        localStorage.setItem('content', htmlContent)
        localStorage.setItem('docName', Document + '.doc')
        navigate("/upload-legal-contract-blockchain?upload")
      }
    } catch (error) {

    }
  }

  const generateAI = async () => {
    try {
      validation();
      if (
        selectedCountry != "" &&
        selectedState != "" &&
        inputValue.Document != ""
      ) {
        var content = `generate a legal document template for : ${inputValue.Document} , for country ${selectedCountry} and state ${selectedState} in html format`;
        setLoader(true);
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "user",
                content: content,
              },
            ],
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " +
                "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",
            },
          }
        );
        if (response) {
          setLoader(false);
          setSelectedCountry([]);
          setSelectedState([]);
          // inputValue.Document = "";
          toast("Document generated successfully");
          sethtmlContent(response.data.choices[0].message.content);
          setdownloadOption(true);
          // docxHtmlRef = response.data.choices[0].message.content;
        } else {
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const viewcancel = () => {
    sethtmlContent("");
  };

  return (
    <>
      <Header />
      <div className="container">
        {htmlContent != "" ?
          <div className="flex-wrap rounded-2.5xl bg-white p-10 dark:bg-jacarta-700 md:flex-nowrap md:space-x-8 md:p-[4.25rem] lg:space-x-16 chat_bg">
            <div className="lg:w-[100%]">
              <div className="mt-5">
                <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white">
                  Generated Document
                </h2>
              </div>
              <div>
                <React.Fragment>
                  <Editor
                    apiKey='3f2stkyg60k3jfga1nx8ro6618r792y7fqyut5f44jw8wl3k'
                    value={htmlContent}
                    init={{
                      // selector: 'textarea',
                      height: "50vh",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen powerpaste casechange",
                        "directionality advcode visualchars mediaembed codesample pagebreak",
                        "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                        "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      menubar: false,
                      content_style: "body img{ width:100%; }",
                      toolbar:
                        "undo redo | styleselect | formatselect | bold italic backcolor | \
                                                             alignleft aligncenter alignright alignjustify | \
                                                             bullist numlist outdent indent | removeformat | table tabledelete | \
                                                             tableprops tablerowprops tablecellprops | \
                                                             tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                                             tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                    }}
                  />
                </React.Fragment>
              </div>
              <div className="button_footer">
                <button
                  onClick={viewcancel}
                  className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Cancel
                </button>
                {DownLoader == true ? (
                  <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={documentDownloader}
                    class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Upload to contract
                  </button>
                )}
              </div>
            </div>
          </div>
          : ""}
        <div className="previe_docs">
          {htmlContent == "" ? (
            <div className="AIchat2 paddingadd mb-5">
              <div>
                <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white">
                  Law Marketplace Create AI Template
                </h2>
              </div>
              <div className="mx-auto  md:flex wrap_s">
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Country
                    </label>
                    <select
                      class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                      id="cars"
                      name="Country"
                      value={selectedCountry}
                      onChange={(event) =>
                        setSelectedCountry(event.target.value)
                      }
                    >
                      {countries.length > 0 ? (
                        countries.map(({ isoCode, name }) => (
                          <option
                            class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                            value={isoCode}
                            key={isoCode}
                          >
                            {name}
                          </option>
                        ))
                      ) : (
                        <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                          No Countries Found!
                        </option>
                      )}
                    </select>
                    <div>
                      {Countryerr == true ? (
                        <p style={{ color: "red" }}>{validate.Country}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      State
                    </label>
                    <select
                      class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                      name="state"
                      value={selectedState}
                      onChange={(event) =>
                        setSelectedState(event.target.value)
                      }
                    >
                      {states.length > 0 ? (
                        states.map(({ isoCode, name }) => (
                          <option
                            class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                            value={isoCode}
                            key={isoCode}
                          >
                            {name}
                          </option>
                        ))
                      ) : (
                        <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                          No States Found!
                        </option>
                      )}
                    </select>
                    {Stateerr == true ? (
                      <p style={{ color: "red" }}>State is required</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-4 md:w-1/1 md:pr-8 w-100_n">
                  <div class="mb-6 ">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Type of Document
                    </label>
                    <textarea
                      id="profile-bio"
                      name="Document"
                      value={Document}
                      maxLength={160}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      required
                      placeholder="Enter Document"
                      onChange={getValues}
                    ></textarea>
                    <div>
                      {Documenterr == true ? (
                        <p style={{ color: "red" }}>{validate.Document}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {Loader == true ? (
                    <div className="form_betwine w-100_n space_brtenn md:pr-8">
                      <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                        Loading...{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="form_betwine w-100_n space_brtenn md:pr-8">
                      <button
                        class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        onClick={generateAI}
                      >
                        Generate{" "}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Aicreatetemp;
