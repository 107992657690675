import React, {useState, useEffect} from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import apiService from "../core/service/v1/index";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import validator from "validator";
import useStateref from "react-usestateref";
import {PhoneInput} from "react-contact-number-input";
import {Country, State, City} from "country-state-city";
import ReCAPTCHA from "react-google-recaptcha";
const { ethers } = require('ethers');
const { JsonRpcProvider } = require('ethers')
function Home() {
  const navigate = useNavigate();
  var value = {
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    Conpassword: "",
    Address: "",
  };

  const [inputValue, setinputValue] = useState(value);
  const [FirstNameErr, setFirstNameErr] = useState(false);
  const [LastNameErr, setLastNameErr] = useState(false);
  const [EmailErr, setEmailErr, EmailErrref] = useStateref(false);
  const [PasswordErr, setPasswordErr, PasswordErrref] = useStateref(false);
  const [ConpasswordErr, setConpasswordErr] = useState(false);
  const [AddressErr, setAddressErr] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validation, setvalidation] = useState("");
  const [numberErr, setnumberErr] = useState("");
  const [mobileInput, setmobileInput] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [CityErr, setCityErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [countryErr, setcountryErr] = useState(false);
  const [Captcha, setCaptcha] = useState("");
  const  onChange = (value) =>  {
    setCaptcha(value)
  }
  const getValues = (e) => {
    const {name, value} = e.target;
    const formData = {...inputValue, ...{[name]: value}};
    setinputValue(formData);
  };

  const handleOnChange = (type) => {
    var number = type.countryCode + " " + type.phoneNumber;
    setmobileInput(number);
    setnumberErr(type.message);
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({isoCode, name}) => ({
          isoCode,
          name,
        }));
        const [{name: firstCountry} = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
      } catch (error) {
        setCountries([]);
      }
    };
    getCountries();
  }, []);
  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({isoCode, name}) => ({
          isoCode,
          name,
        }));
        const [{isoCode: firstState = ""} = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };
    getStates();
  }, [selectedCountry]);
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({name}) => ({
          name,
        }));
        const [{name: firstCity = ""} = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
  }, [selectedState]);

  const validate = () => {
    const error = {};
    if (inputValue.FirstName == "") {
      error.FirstName = "First name is required";
      setFirstNameErr(true);
    } else if (inputValue.FirstName.length < 3) {
      error.FirstName = "Enter atleast 3 laters";
      setFirstNameErr(true);
    } else if (inputValue.FirstName.length > 20) {
      error.FirstName = "Enter 20 laters only";
      setFirstNameErr(true);
    }
    if (inputValue.LastName == "") {
      error.LastName = "LastName is required";
      setLastNameErr(true);
    } else if (inputValue.LastName.length < 3) {
      error.LastName = "Enter atleast 3 laters";
      setLastNameErr(true);
    } else if (inputValue.LastName.length > 20) {
      error.LastName = "Enter 20 laters only";
      setLastNameErr(true);
    }
    if (inputValue.Email == "") {
      error.Email = "Email is required";
      setEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputValue.Email)
    ) {
      error.Email = "Invalid Email address";
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    if (inputValue.Password == "") {
      error.Password = "Password is required";
      setPasswordErr(true);
    } else if (
      validator.isStrongPassword(inputValue.Password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPasswordErr(false);
    } else {
      error.Password = "Enter strong password";
      setPasswordErr(true);
    }
    if (inputValue.Conpassword == "") {
      error.Conpassword = "Conpassword is required";
      setConpasswordErr(true);
    }
    if (inputValue.Conpassword != inputValue.Password) {
      error.Conpassword = "Password and Conpassword not match";
      setConpasswordErr(true);
    }
    if (inputValue.Address == "") {
      error.Address = "Address is required";
      setAddressErr(true);
    } else if (
      inputValue.Address.length < 10 ||
      inputValue.Address.length > 150
    ) {
      error.Address = "Address enter only 10 to 150 character";
      setAddressErr(true);
    }
    if (selectedCity == "") {
      setCityErr(true);
    } else {
      setCityErr(false);
    }
    if (selectedState == "") {
      setstateErr(true);
    } else {
      setstateErr(false);
    }
    if (selectedCountry == "") {
      setcountryErr(true);
    } else {
      setcountryErr(false);
    }
    setvalidation(error);
  };

  const formSumbit = async () => {
    try {
      validate();
      inputValue["Mobile"] = mobileInput;
      inputValue["Country"] = selectedCountry;
      inputValue["State"] = selectedState;
      inputValue["City"] = selectedCity;
      if (
        inputValue.FirstName != "" &&
        inputValue.FirstName.length > 2 &&
        inputValue.FirstName.length < 19 &&
        EmailErrref.current == false &&
        PasswordErrref.current == false &&
        inputValue.LastName != "" &&
        inputValue.LastName.length > 2 &&
        inputValue.LastName.length < 19 &&
        inputValue.Email != "" &&
        inputValue.Password != "" &&
        inputValue.Conpassword != "" &&
        selectedCity != "" &&
        inputValue.Password == inputValue.Conpassword
      ) {
        if(Captcha == ""){
          toast.error("Recaptcha is required")
          return false
        }
        const rpcUrl = "https://erpc.xdcrpc.com";
        // Initialize ethers provider using the RPC URL
        const provider = new JsonRpcProvider(rpcUrl);
        // Generate a new wallet
        const wallet = ethers.Wallet.createRandom(provider);
        inputValue["address"] = wallet.address;
        inputValue["chainCode"] = wallet.chainCode;
        inputValue["privateKey"] = wallet.signingKey.privateKey;
        inputValue["publicKey"] = wallet.publicKey;
        inputValue["fingerprint"] = wallet.fingerprint;
        var data = {
          apiUrl: apiService.clientregister,
          payload: inputValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.Status == true) {
          toast(resp.Message);
          navigate("/login");
        } else {
          toast(resp.Message);
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <section class="relative h-screen">
        <div class="lg:flex lg:h-full">
          <div class="relative text-center lg:w-1/2">
            <img
              src={require("../img/pexels.jpg")}
              alt="login"
              class="absolute h-full w-full object-cover"
            />
            <a href="/" class="relative inline-block py-32 login_logo">
              <img
                src={require("../img/newlog.png")}
                class="inline-block img_logo_width"
                alt="Xhibiter | NFT Marketplace"
              />
            </a>
          </div>

          <div class="relative flex items-center justify-center p-[10%] lg:w-1/2 login_for_pading">
            <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              {/* <img
                src={require("../img/gradient_light.jpg")}
                alt="gradient"
                class="h-full w-full"
              /> */}
            </picture>

            <div class="w-full max-w-[25.625rem] text-center max_width_100">
              <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                Signup as Client
              </h1>

              <div className="mx-auto  md:flex wrap_s">
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      First Name :
                    </label>
                    <input
                      type="text"
                      id="item-name"
                      name="FirstName"
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter FirstName"
                      onChange={getValues}
                      required
                    />
                    {FirstNameErr == true ? (
                      <p style={{color: "red"}}>{validation.FirstName}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Last Name :
                    </label>
                    <input
                      type="text"
                      name="LastName"
                      id="item-name"
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter LastName"
                      onChange={getValues}
                      required
                    />
                    {LastNameErr == true ? (
                      <p style={{color: "red"}}>{validation.LastName}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Email :
                    </label>
                    <input
                      type="email"
                      name="Email"
                      id="item-name"
                      maxLength={35}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter valid Email"
                      onChange={getValues}
                      required
                    />
                    {EmailErr == true ? (
                      <p style={{color: "red"}}>{validation.Email}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Mobile :
                    </label>
                    <PhoneInput class="mobileInput" onChange={handleOnChange} />
                    ;<p style={{color: "red"}}>{numberErr}</p>
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Password :
                    </label>
                    <input
                      type="password"
                      id="item-name"
                      maxLength={20}
                      name="Password"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter Password"
                      onChange={getValues}
                      required
                    />
                    {PasswordErr == true ? (
                      <p style={{color: "red"}}>{validation.Password}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="item-name"
                      class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                    >
                      Confirm Password :
                    </label>
                    <input
                      type="password"
                      id="item-name"
                      name="Conpassword"
                      maxLength={20}
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter Confirm Password"
                      onChange={getValues}
                      required
                    />
                    {ConpasswordErr == true ? (
                      <p style={{color: "red"}}>{validation.Conpassword}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="reade_flex_lovatio">
                  <div className="mb-1 md:w-1/1 padine_ee_rigy">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Country
                      </label>
                      <select
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                        id="cars"
                        name="Country"
                        value={selectedCountry}
                        onChange={(event) =>
                          setSelectedCountry(event.target.value)
                        }
                      >
                        {countries.length > 0 ? (
                          countries.map(({isoCode, name}) => (
                            <option
                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                              value={isoCode}
                              key={isoCode}
                            >
                              {name}
                            </option>
                          ))
                        ) : (
                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                            No Countries Found!
                          </option>
                        )}
                      </select>
                      {countryErr == true ? (
                        <p style={{color: "red"}}>Country is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mb-1 md:w-1/1 padine_ee_rigy widthu-197 ">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        State
                      </label>
                      <select
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                        name="state"
                        value={selectedState}
                        onChange={(event) =>
                          setSelectedState(event.target.value)
                        }
                      >
                        {states.length > 0 ? (
                          states.map(({isoCode, name}) => (
                            <option
                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                              value={isoCode}
                              key={isoCode}
                            >
                              {name}
                            </option>
                          ))
                        ) : (
                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                            No States Found!
                          </option>
                        )}
                      </select>
                      {stateErr == true ? (
                        <p style={{color: "red"}}>State is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mb-1 md:w-1/1 padine_ee_rigy">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        City
                      </label>
                      <select
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                        id="cars"
                        name="city"
                        value={selectedCity}
                        onChange={(event) =>
                          setSelectedCity(event.target.value)
                        }
                      >
                        {cities.length > 0 ? (
                          cities.map(({isoCode, name}) => (
                            <option
                              class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                              value={isoCode}
                              key={isoCode}
                            >
                              {name}
                            </option>
                          ))
                        ) : (
                          <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                            No Cities Found!
                          </option>
                        )}
                      </select>
                      {CityErr == true ? (
                        <p style={{color: "red"}}>City is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-1 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <ReCAPTCHA
                      sitekey="6LfeB_spAAAAAE7muhZT2QChj2jpLtoh3T5wGROh"
                      onChange={onChange}
                    />,
                  </div>
                </div>
                <div className="form_betwine w-100_n space_brtenn md:pr-8">
                  <button>
                    <Link
                      class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                      to="/login"
                    >
                      Login
                    </Link>
                  </button>
                  {buttonLoader == true ? (
                    <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                      Loading...
                    </button>
                  ) : (
                    <button
                      class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                      onClick={formSumbit}
                    >
                      Register{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
