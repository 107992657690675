import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header"
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import LawContract from "../core/helper/lawblocks.json";
import LawTokenContract from "../core/helper/lawblockstoken.json";
import { getWeb3, keys, } from "../core/helper/getWeb3";
import { diffChars } from 'diff';
import moment from "moment"
import * as pdfjsLib from 'pdfjs-dist';
import { create } from 'ipfs-http-client';
import {
    LAWBLOCKS_CONTRACT,
} from "../core/helper/constant";
import { NFTStorage } from 'nft.storage'
import { Editor } from "@tinymce/tinymce-react";
import { Buffer } from "buffer";
import countryList from 'react-select-country-list'
import Select from 'react-select'
import useStateRef from "react-usestateref";
import HtmlDiff from "htmldiff-js";
import { useNavigate } from "react-router-dom";
const FormData = require('form-data');
var mammoth = require("mammoth");
const { ethers } = require('ethers');
const { JsonRpcProvider, Wallet } = ethers;

function Contract() {

    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        setValue(value)
    }
    const reader = new FileReader();
    var address = ''
    var web3 = getWeb3();
    const NFT_STORAGE_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMkM5YTUxQWE2NmUzMTk2YjJjQTdBODg2NjFkMTUwODVjMDczODAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUyNTQxMzIxOSwibmFtZSI6ImhvcHB5aGFyZXMifQ.CYTqIjhUqVUlViBTjN-mmQoDvBugYwghGIpTc7C6DoY";
    const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });

    const apiKey = '73e75cf7fb194db4869ff36e1084cd2d';
    const apiSecret = 'YDcoUylIkgQpxaGSYMOv6DWNZVqhZ+4Vt7EWetY5+p7BKW3UcU1/cg';
    const auth = 'Basic ' + Buffer.from(apiKey + ':' + apiSecret).toString('base64');

    const ipfs = create({
        host: 'ipfs.infura.io',
        port: 5001,
        protocol: 'https',
        headers: {
            authorization: auth,
        },
    });


    const [uploadFile, setuploadFile] = useState("")
    const [shortcontent, setshortcontent] = useState("")
    const [draftList, setdraftList] = useState([])
    const [shareList, setshareList] = useState([])
    const [uploadList, setuploadList] = useState([])

    useEffect(() => {

        if (window.location.href.split('?')[1] != undefined) {
            var content = localStorage.getItem("content")
            setuploadFile(localStorage.getItem('docName'));
            console.log('.' + localStorage.getItem('docName').split('.')[1], "===")
            setfileFormat('.' + localStorage.getItem('docName').split('.')[1]);
            sethtmloriginalDraft(content);
            setshortcontent(content)
        }
        getDraftlist()
        getsharelist()
        getuploadlist()
        web3 = getWeb3()
    }, [0])
    const viewHash = (hash) => {
        window.open(
            `https://explorer.xinfin.network/tx/${hash}#overview`
        );
    }
    const toXdc = (addrass) => {
        return 'xdc' + String(addrass).slice(2)
    }
    const form = new FormData();

    const [savetab, setsavetab] = useState("uploadtbl")
    const [uploadtab, setuploadtab] = useState("uploadtbl d-none")
    const [Sharetab, setSharetab] = useState("uploadtbl d-none")
    const [editDraft, seteditDraft] = useState([])
    const tabletab = (tab) => {
        if (tab == "Save") {
            setsavetab("uploadtbl")
            setuploadtab("uploadtbl d-none")
            setSharetab("uploadtbl d-none")
        }
        if (tab == "Uploaded") {
            setsavetab("uploadtbl d-none")
            setuploadtab("uploadtbl ")
            setSharetab("uploadtbl d-none")
        }
        if (tab == "Shared") {
            setsavetab("uploadtbl d-none")
            setuploadtab("uploadtbl d-none")
            setSharetab("uploadtbl ")
        }
    }
    const [viewAction, setviewAction] = useState(false)
    const [editAction, seteditAction] = useState(false)
    const [shareAction, setshareAction] = useState(false)
    const [showfilename, setshowfilename] = useState("")
    const [htmloriginalDraft, sethtmloriginalDraft, htmloriginalDraftref] = useStateRef("")
    const [originalDraft, setoriginalDraft, originalDraftref] = useStateRef("")
    const [fileFormat, setfileFormat] = useState("")
    const [_id, set_id] = useState("")

    const actionTab = async (key, id, format) => {
        set_id(id)
        if (key == "delete") {
            var data = {
                apiUrl: apiService.deleteFile,
                payload: { format: id },
            };
            var resp = await postMethod(data)
            if (resp.status == true) {
                getDraftlist()
                toast.success(resp.Message)
            } else {
                toast.error(resp.Message)
            }
        }
        if (key == "view") {
            setfileFormat(format.split('.')[1])
            if (id) {
                var data = {
                    apiUrl: apiService.getChooseDraft,
                    payload: { format: id },
                };
                var resp = await postMethod(data)
                if (resp.status == true) {
                    var last = {}

                    if (resp.data.editedDraft.length == 0) {
                        setshowfilename(resp.data.draftName)
                        sethtmloriginalDraft(resp.data.originalDraft)
                    } else {
                        for (let i = 0; i < resp.data.editedDraft.length; i++) {
                            const element = resp.data.editedDraft[i];
                            last = element
                        }
                        sethtmloriginalDraft(last.changes)

                    }

                    setviewAction(true)
                    seteditAction(false)
                    setshareAction(false)
                }
            }
        }
        if (key == "edit") {
            if (id) {
                var data = {
                    apiUrl: apiService.getChooseDraft,
                    payload: { format: id },
                };
                var resp = await postMethod(data)
                if (resp.status == true) {
                    var last = {}
                    if (resp.data.editedDraft.length == 0) {
                        setshowfilename(resp.data.draftName)
                        setuploadFile(resp.data.draftName)
                        sethtmloriginalDraft(resp.data.originalDraft)
                        setshortcontent(resp.data.originalDraft)
                    } else {
                        for (let i = 0; i < resp.data.editedDraft.length; i++) {
                            const element = resp.data.editedDraft[i];
                            last = element
                        }
                        setoriginalDraft(resp.data.originalDraft)
                        sethtmloriginalDraft(last.changes)
                        setshortcontent(resp.data.originalDraft)
                        setuploadFile(resp.data.draftName)

                    }
                    seteditDraft(resp.data.editedDraft)
                    setviewAction(false)
                    seteditAction(true)
                    setshareAction(false)
                }
            }
        }
        if (key == "share") {
            setviewAction(false)
            seteditAction(false)
            setshareAction(true)
            for (let i = 0; i < draftList.length; i++) {
                const element = draftList[i];
                if (id == element._id) {
                    setshowfilename(element.draftName)
                    sethtmloriginalDraft(element.originalDraft)
                }
            }
        }
        if (key == "cancel") {
            setviewAction(false)
            seteditAction(false)
            setshareAction(false)
            // setsavetab("uploadtbl")
            // setuploadtab("uploadtbl d-none")
            // setSharetab("uploadtbl d-none")
        }
    }

    const actionTab2 = async (key, id, format) => {
        set_id(id)
        if (key == "view") {

            setfileFormat(format.split('.')[1])
            if (id) {
                var data = {
                    apiUrl: apiService.getChooseDraft,
                    payload: { format: id },
                };
                var resp = await postMethod(data)
                if (resp.status == true) {
                    var last = {}
                    if (resp.data.editedDraft.length == 0) {
                        setshowfilename(resp.data.draftName)
                        sethtmloriginalDraft(resp.data.originalDraft)
                    } else {
                        for (let i = 0; i < resp.data.editedDraft.length; i++) {
                            const element = resp.data.editedDraft[i];
                            last = element
                        }
                        sethtmloriginalDraft(last.changes)

                    }
                    setviewAction(true)
                    seteditAction(false)
                    setshareAction(false)
                }
            }
        }
        if (key == "edit") {
            if (id) {
                var data = {
                    apiUrl: apiService.getChooseDraft,
                    payload: { format: id },
                };
                var resp = await postMethod(data)
                if (resp.status == true) {
                    var last = {}
                    if (resp.data.editedDraft.length == 0) {
                        setshowfilename(resp.data.draftName)
                        sethtmloriginalDraft(resp.data.originalDraft)
                        setshortcontent(resp.data.originalDraft)
                        // setuploadFile(resp.data.draftName)

                    } else {
                        for (let i = 0; i < resp.data.editedDraft.length; i++) {
                            const element = resp.data.editedDraft[i];
                            last = element
                        }
                        setoriginalDraft(resp.data.originalDraft)
                        sethtmloriginalDraft(last.changes)
                        setshortcontent(resp.data.originalDraft)
                        // setuploadFile(resp.data.draftName)
                    }
                    setviewAction(false)
                    seteditAction(true)
                    setshareAction(false)
                }
            }
        }
        if (key == "share") {
            setviewAction(false)
            seteditAction(false)
            setshareAction(true)
            for (let i = 0; i < draftList.length; i++) {
                const element = draftList[i];
                if (id == element._id) {
                    // set_id(element._id)
                    setshowfilename(element.draftName)
                    sethtmloriginalDraft(element.originalDraft)
                }
            }
        }
        if (key == "cancel") {
            setviewAction(false)
            seteditAction(false)
            setshareAction(false)
            setsavetab("uploadtbl")
            setuploadtab("uploadtbl d-none")
            setSharetab("uploadtbl d-none")
        }
    }

    const [loading, setloading] = useState(false)

    const Upload_contact = async (type) => {
        try {

            if (type == 'nor') {
                if (value == '') {
                    toast.error("Please select origin")
                    return
                }
            }
            setloading(true)
            if (!web3.currentProvider) {
                toast.error('Connection Error');
                setloading(false)
                return;
            }
            const key = await keys();
            // Ensure web3 instance is correctly connected
            const isListening = await web3.eth.net.isListening();
            if (!isListening) {
                setloading(false)
                toast.error('Web3 is not connected to the network');
                return;
            }

            // Get balance
            const address = web3.eth.accounts.wallet[0].address; // Replace with your wallet address
            let balance = await web3.eth.getBalance(address);
            balance = web3.utils.fromWei(balance, 'ether');

            // Get gas price
            const gasPrice = await web3.eth.getGasPrice();
            const getGas = Number(gasPrice);

            if (isNaN(getGas)) {
                setloading(false)
                throw new Error('Gas price is NaN');
            }
            const gasLimit = 2100000;

            var fee = getGas / 1000000000;
            var fee1 = fee / 1000000000;
            var fee2 = fee1 * 2100000;
            var limit = fee1 + 0.00007882225;
            if (balance <= limit) {
                setloading(false)
                toast.error('Insufficient balance');
                return;
            }
            var ipfsHash;
            const fileBuffer = new Blob([shortcontent], {
                type:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const result = await ipfs.add(fileBuffer)
            console.log(result, "=====result")
            const signature = web3.eth.accounts.sign(result.path, key);
            if (!signature) {
                setloading(false)
                toast.error('Failed to sign IPFS hash');
                return;
            }
            const ethersProvider = new JsonRpcProvider(
                "https://earpc.xinfin.network"
            );
            const ethersWallet = new ethers.Wallet(key, ethersProvider);
            const lawEthersInstance = new ethers.Contract(
                LAWBLOCKS_CONTRACT,
                LawContract,
                ethersWallet
            );

            var txConfig = {
                gasLimit: "2100000",
            };

            const uploadHash = await lawEthersInstance.uploadFile(
                result.path.toString(),
                uploadFile.toString(),
                signature.toString(),
                txConfig
            );
            const documentNo = await lawEthersInstance._contractIds();
            const Data = {
                fileName: uploadFile.toString(),
                txnHash: uploadHash.hash.toString(),
                ipfsHash: result.path.toString(),
                documentNo: documentNo.toString(),
                walletAddress: toXdc(address).toString(),
                storageType: "IPFS",
            };
            var data = {
                apiUrl: apiService.UploadFile,
                payload: Data,
            };
            var resp = await postMethod(data)
            if (resp.Status == true) {
                toast.success(resp.Message)
                setuploadFile("")
                setloading(false)
                getuploadlist()
            } else {
                toast.error(resp.Message)
                setloading(false)
            }
        } catch (e) {
            toast.error(e.Message)
            setloading(false)
        }
    }
    const [email, setemail] = useState("")
    const [loadingbutton, setloadingbutton] = useState(false)
    const [fileDetails, setfileDetails] = useState("")

    const ShareFile = async () => {
        try {
            var obj = {
                _id: _id,
                email: email
            }
            var data = {
                apiUrl: apiService.ShareFile,
                payload: obj,
            };
            setloadingbutton(true)
            var resp = await postMethod(data)
            setloadingbutton(false)
            if (resp.status == true) {
                toast.success(resp.Message)
                setviewAction(false)
                seteditAction(false)
                setshareAction(false)
            } else {
                toast.error(resp.Message)
            }
        } catch (error) {

        }
    }

    const extractHTMLFromPDF = async (file) => {
        const pdfData = new Uint8Array(await file.arrayBuffer());
        const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
        const numPages = pdf.numPages;
        let htmlContent = '<!DOCTYPE html><html><head><title>Document</title></head><body>';

        for (let i = 1; i <= numPages; i++) {
            const page = await pdf.getPage(i);
            const content = await page.getTextContent();

            let pageHTML = '';

            content.items.forEach(item => {
                // Basic content categorization (you may need more advanced logic here)
                const text = item.str.trim();

                if (text.length === 0) return; // Skip empty text

                // Example of determining a heading based on font size
                if (item.height > 20) {
                    pageHTML += `<h1>${text}</h1>`;
                } else if (text.startsWith('•') || text.startsWith('-')) {
                    // Example of list item detection
                    pageHTML += `<li>${text.substring(1).trim()}</li>`;
                } else {
                    pageHTML += `<p>${text}</p>`;
                }
            });

            htmlContent += pageHTML;
        }

        htmlContent += '</body></html>';
        return htmlContent;
    };


    const getfile = async (file) => {
        try {
            var fileName = file.name;
            setfileDetails(file)
            var validExts = new Array(
                ".png",
                ".jpg",
                ".jpeg",
                ".gif",
                ".tiff",
                ".psd",
                ".pdf",
                ".eps",
                ".ai",
                ".indd",
                ".raw",
                "webm",
                "mpg",
                "mp2",
                ".mpeg",
                ".mpe",
                ".mpv",
                ".ogg",
                ".md4",
                ".m4p",
                ".m4v",
                ".avi",
                ".wmv",
                ".qt",
                ".flv",
                "..swf",
                "avchd",
                ".doc",
                ".docx",
                ".html",
                ".htm",
                ".xls",
                ".xlsx",
                ".odt",
                ".ods",
                ".ppt",
                ".pptx",
                ".txt"
            );
            var fileExt = fileName;
            fileExt = fileExt.substring(fileExt.lastIndexOf("."));
            if (validExts.indexOf(fileExt) < 0) {

                toast.error("Invalid file selected, valid files are of " +
                    validExts.toString() +
                    " types.")
                return false
            }

            setfileFormat(fileExt)
            const reader = new FileReader();
            if (fileExt === ".docx") {
                reader.onload = async (e) => {
                    const content = e.target.result;
                    const result = await mammoth.convertToHtml({ arrayBuffer: content });
                    if (result) {
                        setuploadFile(file.name);
                        sethtmloriginalDraft(result.value);
                        setshortcontent(result.value);
                    }
                };

                reader.readAsArrayBuffer(file);

            } else if (fileExt === ".doc") {

                reader.onload = async (e) => {
                    const pdfData = new Uint8Array(e.target.result);

                    // Load the PDF document
                    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
                    const page = await pdf.getPage(1);

                    // Render the page
                    const viewport = page.getViewport({ scale: 1 });
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render the page into the canvas context
                    await page.render({ canvasContext: context, viewport: viewport }).promise;

                    // Convert the canvas to a data URL
                    const imgData = canvas.toDataURL('image/png');
                    sethtmloriginalDraft(`<img src="${imgData}" alt="PDF page 1">`);
                };
                reader.readAsArrayBuffer(file);
                return
                reader.onload = async (e) => {
                    const content = e.target.result;
                    const result = await mammoth.convertToHtml({ arrayBuffer: content });
                    if (result) {
                        setuploadFile(file.name);
                        sethtmloriginalDraft(result.value);
                        setshortcontent(result.value);
                    }
                }
                reader.readAsArrayBuffer(file);

            } else if (fileExt != ".pdf") {
                reader.onload = async (e) => {
                    var image = e.target.result;
                    var ImageUrl = image;
                    const base64Response = await fetch(ImageUrl);
                    const blobs = await base64Response.blob();
                    var files = new Blob([blobs], {
                        type: `application/${String(fileExt).substring(1)}`,
                    });
                    setuploadFile(file.name)
                    sethtmloriginalDraft(ImageUrl);
                    setshortcontent(ImageUrl)
                };
                reader.readAsDataURL(file);
            } else {
                const textContent = await extractHTMLFromPDF(file);
                setuploadFile(file.name);
                sethtmloriginalDraft(`<p>${textContent.replace(/\n/g, '<br/>')}</p>`);
                setshortcontent(`<p>${textContent.replace(/\n/g, '<br/>')}</p>`);
                return
                reader.onload = async (e) => {
                    const pdfData = new Uint8Array(e.target.result);

                    // Load the PDF document
                    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
                    const page = await pdf.getPage(1);

                    // Render the page
                    const viewport = page.getViewport({ scale: 1 });
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render the page into the canvas context
                    await page.render({ canvasContext: context, viewport: viewport }).promise;

                    // Convert the canvas to a data URL
                    const imgData = canvas.toDataURL('image/png');
                    setuploadFile(file.name);
                    sethtmloriginalDraft(`<img src="${imgData}" alt="PDF page 1">`);
                    setshortcontent(`<img src="${imgData}" alt="PDF page 1">`);
                };
                reader.readAsArrayBuffer(file);
            }
        } catch (error) {

        }
    }
    const [draftLoad, setdraftLoad] = useState(false)
    const SaveDraft = async () => {
        try {
            if (value != "" && value != undefined) {
                const Data = {
                    originalDraft: htmloriginalDraftref.current,
                    draftName: uploadFile,
                };
                var data = {
                    apiUrl: apiService.createDraft,
                    payload: Data,
                };
                setdraftLoad(true)
                var resp = await postMethod(data)
                setdraftLoad(false)
                if (resp) {
                    toast.success(resp.Message)
                    setuploadFile("")
                    navigate('/upload-legal-contract-blockchain')
                    setuploadFile("")
                    getDraftlist()
                } else {

                }
            } else {
                toast.error("Enter Origin of Document")
            }
        } catch (error) {

        }
    }

    const getDraftlist = async () => {
        var data = {
            apiUrl: apiService.getDraftlist,
        };
        var resp = await postMethod(data)
        if (resp.status == true) {
            setdraftList(resp.data)
        }
    }

    const getsharelist = async () => {
        try {
            var data = {
                apiUrl: apiService.getsharelist,
            };
            var resp = await postMethod(data)
            if (resp.status == true) {
                setshareList(resp.data)
            }
        } catch (error) {

        }
    }
    const getuploadlist = async () => {
        try {
            var data = {
                apiUrl: apiService.getuploadlist,
            };
            var resp = await postMethod(data)
            if (resp.status == true) {
                setuploadList(resp.data)
            }
        } catch (error) {

        }
    }
    const editTab = (contentVal) => {
        sethtmloriginalDraft(contentVal)
    }


    const generateHighlightedHTML = (oldStr, newStr) => {



        function firstHtml() {
            return { __html: oldStr };
        }
        function secondHtml() {
            return { __html: newStr };
        }

        function diffHtml() {
            return {
                __html: HtmlDiff.execute(firstHtml().__html, secondHtml().__html)
            };
        }
        console.log(diffHtml().__html, "==")
        return diffHtml().__html
        const diff = diffChars(oldStr, newStr);
        let result = '';

        diff.forEach(part => {
            if (part.added) {
                // Wrap added parts with a custom class or inline style for highlighting
                result += `<span class="added" style="background-color: lightgreen;">${part.value}</span>`;
            } else if (part.removed) {
                // Wrap removed parts with a custom class or inline style for highlighting
                result += `<span class="removed" style="background-color: salmon;">${part.value}</span>`;
            } else {
                // Unchanged parts remain as is
                result += part.value;
            }
        });
        console.log(result, "=oldStr============================");
        return result;
    };

    const SaveEditfile = async () => {
        try {
            var obj = {
                _id: _id,
                originalDraft: htmloriginalDraftref.current
            }
            var data = {
                apiUrl: apiService.editSavedraft,
                payload: obj
            };
            setdraftLoad(true)
            var resp = await postMethod(data)
            setdraftLoad(false)
            if (resp.status == true) {
                toast.success(resp.Message)
                setviewAction(false)
                seteditAction(false)
                setshareAction(false)
                getDraftlist()
            } else {
                toast.error(resp.Message)
                setviewAction(false)
                seteditAction(false)
                setshareAction(false)
            }

        } catch (error) {

        }
    }
    const [previewAction, setpreviewAction] = useState(false)

    // const pdfDownload = (htmlContent) => {
    //     const element = document.createElement('div');
    //     element.innerHTML = htmlContent;

    //     html2pdf()
    //         .from(element)
    //         .save('edited.pdf');
    // };

    return (
        <>
            <Header />
            <section className="section">
                <div className="container">
                    <div className="chat"></div>
                </div>
            </section>
            <div className="container">
                <div className=" flex-wrap rounded-2.5xl bg-white p-10 dark:bg-jacarta-700 md:flex-nowrap md:space-x-8 md:p-[4.25rem] lg:space-x-16 chat_bg">
                    <div className="lg:w-[100%]">
                        {viewAction == false && editAction == false && shareAction == false ?
                            <div class="container" id="kt_content_container">
                                <div class="mb-10 ">
                                    <div style={{ "position": "relative" }}>
                                        <div
                                            class="group relative flex max-w-xxl flex-col  items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100  py-20 mb-5 px-5 text-center">
                                            <div class="relative z-10 cursor-pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    class="mb-4 inline-block fill-jacarta-500" viewBox="0 0 24 24"
                                                    fill="currentColor">
                                                    <path
                                                        d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
                                                </svg>
                                                <p class="mx-auto max-w-xs text-l dark:text-jacarta-300">Click Here
                                                    to Upload File</p>
                                            </div>
                                            <div
                                                class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0 dark:bg-jacarta-600"></div>
                                            <input type="file" id="file-upload" onChange={(e) => getfile(e.target.files[0])}
                                                class="absolute inset-0 z-20 cursor-pointer opacity-0" />
                                        </div>
                                    </div>

                                    {uploadFile != "" ?
                                        <div class="imadsetfd mb-5">
                                            <div>
                                                <div>
                                                    <div style={{ 'display': 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'align-items': 'center' }}>

                                                        {previewAction == true ?
                                                            <>
                                                                {fileFormat == ".docx" || fileFormat == ".doc" || fileFormat == ".pdf" ?
                                                                    <div style={{ "width": "800px" }}>
                                                                        <React.Fragment>
                                                                            <Editor
                                                                                disabled
                                                                                apiKey='3f2stkyg60k3jfga1nx8ro6618r792y7fqyut5f44jw8wl3k'
                                                                                value={htmloriginalDraftref.current}
                                                                                init={{
                                                                                    // selector: 'textarea',
                                                                                    height: "50vh",
                                                                                    plugins: [
                                                                                        "advlist autolink lists link image charmap print preview anchor",
                                                                                        "searchreplace visualblocks code fullscreen powerpaste casechange",
                                                                                        "directionality advcode visualchars mediaembed codesample pagebreak",
                                                                                        "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                                                                                        "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                                                                                        "insertdatetime media table paste code help wordcount",
                                                                                    ],
                                                                                    menubar: false,
                                                                                    content_style: "body img{ width:100%; }",
                                                                                    toolbar:
                                                                                        "undo redo | styleselect | formatselect | bold italic backcolor | \
                                                             alignleft aligncenter alignright alignjustify | \
                                                             bullist numlist outdent indent | removeformat | table tabledelete | \
                                                             tableprops tablerowprops tablecellprops | \
                                                             tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                                             tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                                                                                }}
                                                                            />
                                                                        </React.Fragment>
                                                                    </div> : <img src={htmloriginalDraft} />}
                                                            </> : <span style={{ 'font-size': '200px' }}><i class="ri-file-pdf-line image_icon"></i></span>}

                                                        {/* <span  style={{ 'font-size': '200px' }}><i class="ri-file-pdf-line image_icon"></i></span> */}

                                                        <b class="TextPrimary">File Name : {uploadFile}</b></div>
                                                    <div style={{ 'display': 'flex', 'flex-direction': 'row', 'justify-content': 'space-between' }}>

                                                        <div class="btncountry">
                                                            <div class="mb-6">
                                                                <label
                                                                    for="item-name"
                                                                    class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                                                                >
                                                                    Origin of Document<span class="text-red">*</span>
                                                                </label>
                                                                <Select options={options} value={value} onChange={changeHandler} />
                                                            </div>
                                                            <label
                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                {draftLoad == false ?
                                                                    <span class="dark:text-white group-hover:text-white" onClick={SaveDraft}>Save As Draft</span> :
                                                                    <span class="dark:text-white group-hover:text-white" >Loading...</span>}
                                                            </label>
                                                            <label
                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                {loading == false ? <button
                                                                    class="dark:text-white group-hover:text-white" onClick={() => Upload_contact("nor")}>Upload Contract</button> : <button
                                                                        class="dark:text-white group-hover:text-white" >Loading...</button>}
                                                            </label>
                                                            {previewAction == false ?
                                                                <label
                                                                    class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                    <button onClick={() => setpreviewAction(true)} class="dark:text-white group-hover:text-white" >Preview</button></label> :
                                                                <label class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                    <button onClick={() => setpreviewAction(false)} class="dark:text-white group-hover:text-white" >Close</button></label>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}
                                </div>
                                <div class="row">
                                    <ul class="nav nav-tabs flex items-center justify-center flex-wrap" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button onClick={() => tabletab("Save")}
                                                class="nav-link active relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                                                id="offers-tab" data-bs-toggle="tab" data-bs-target="#offers" type="button"
                                                role="tab" aria-controls="offers" aria-selected="true">
                                                <span class="font-display text-base font-medium">Saved Drafts ( {draftList.length} )</span>
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button onClick={() => tabletab("Uploaded")}
                                                class="nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                                                id="offers-tab" data-bs-toggle="tab" data-bs-target="#offers" type="button"
                                                role="tab" aria-controls="offers" aria-selected="true">
                                                <span class="font-display text-base font-medium">Uploaded Contract ( {uploadList.length} )</span>
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button data-toggle="modal" data-target="#myModal" onClick={() => tabletab("Shared")}
                                                class="nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                                                id="offers-tab" data-bs-toggle="tab" data-bs-target="#offers" type="button"
                                                role="tab" aria-controls="offers" aria-selected="true">
                                                <span class="font-display text-base font-medium" >Shared Contract ({shareList.length})</span>
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="col-md-12">
                                        <div class="card mb-5 pt-4 mb-xl-10">
                                            <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg ">
                                                <div class={savetab}>
                                                    <table class="table-cls table_sing_list">
                                                        <thead class="">
                                                            <tr>
                                                                <th class="">S.No</th>
                                                                <th class="">Draft Name</th>
                                                                <th class="">Owner</th>
                                                                <th class="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {draftList.map((data, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{data.draftName}</td>
                                                                        <td>{data.userName}</td>
                                                                        <td className="d_flex">
                                                                            <label
                                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                <span
                                                                                    class="dark:text-white group-hover:text-white">
                                                                                    <button class="btn btn-sm btn-primary" onClick={() => actionTab("view", data._id, data.draftName)}>View</button>
                                                                                </span>
                                                                            </label>
                                                                            {data.draftName.split('.')[1] == 'doc' || data.draftName.split('.')[1] == 'docx' || data.draftName.split('.')[1] == 'pdf' ?
                                                                                <>
                                                                                    <label
                                                                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                        <span
                                                                                            class="dark:text-white group-hover:text-white">
                                                                                            <button class="btn btn-sm btn-primary" onClick={() => actionTab("edit", data._id, data.draftName)}>Edit</button>
                                                                                        </span>
                                                                                    </label>
                                                                                </>
                                                                                : ""}
                                                                            <label
                                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                <span
                                                                                    class="dark:text-white group-hover:text-white">
                                                                                    <button class="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal" onClick={() => actionTab("share", data._id, data.draftName)}>Share</button>
                                                                                </span>
                                                                            </label>
                                                                            <label
                                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                <span
                                                                                    class="dark:text-white group-hover:text-white">
                                                                                    <button class="btn btn-sm btn-primary" onClick={() => actionTab("delete", data._id, data.draftName)}>Delete</button>
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class={uploadtab}>
                                                    <table class="bordered hover responsive table">
                                                        <thead class="border-gray-200 fs-5 fw-bold bg-lighten">
                                                            <tr>
                                                                <th class="">S.No</th>
                                                                <th class="">File Name</th>
                                                                <th class="">Transaction Hash</th>
                                                                <th class="">View</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-6 fw-bold text-gray-600 tr_height" >
                                                            {uploadList.map((data, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{data.fileName}</td>
                                                                        <td>{data.ipfsHash.slice(0, 20) + '.....'}</td>
                                                                        <td className="d_flex"><label
                                                                            class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer"><span
                                                                                class="dark:text-white group-hover:text-white" onClick={() => viewHash(data.txnHash)}><button
                                                                                    class="btn btn-sm btn-primary">view</button></span></label></td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class={Sharetab}>
                                                    <table class="bordered hover responsive table">
                                                        <thead class="border-gray-200 fs-5 fw-bold bg-lighten">
                                                            <tr>
                                                                <th class="">S.No</th>
                                                                <th class="">Draft Name</th>
                                                                <th class="">Owner</th>
                                                                <th class="">View</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-6 fw-bold text-gray-600 tr_height" >
                                                            {shareList.map((data, i) => {
                                                                console.log(data.draftName.split('.')[1])
                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{data.draftName}</td>
                                                                        <td>{data.userName}</td>
                                                                        <td className="d_flex">
                                                                            <label
                                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                <span
                                                                                    class="dark:text-white group-hover:text-white">
                                                                                    <button class="btn btn-sm btn-primary" onClick={() => actionTab2("view", data._id, data.draftName)}>View</button>
                                                                                </span>
                                                                            </label>
                                                                            {data.draftName.split('.')[1] == 'doc' || data.draftName.split('.')[1] == 'docx' || data.draftName.split('.')[1] == 'pdf' ?
                                                                                <>
                                                                                    <label
                                                                                        class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                        <span
                                                                                            class="dark:text-white group-hover:text-white">
                                                                                            <button class="btn btn-sm btn-primary" onClick={() => actionTab2("edit", data._id, data.draftName)}>Edit</button>
                                                                                        </span>
                                                                                    </label>
                                                                                </>
                                                                                : ""}
                                                                            <label
                                                                                class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 crebtn rounded-lg border border-jacarta-100 bg-white py-2.5 px-4 mx-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700 cursor-pointer">
                                                                                <span
                                                                                    class="dark:text-white group-hover:text-white">
                                                                                    <button class="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal" onClick={() => actionTab2("share", data._id, data.draftName)}>Share</button>
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="myModal" role="dialog">
                                        <div class="modal-dialog">
                                            {/* <!-- Modal content--> */}
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                                    <h4 class="modal-title">Modal Header</h4>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Some text in the modal.</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                        {viewAction == true ?
                            <div>
                                <div className="mt-5">
                                    <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white">
                                        {showfilename}
                                    </h2>
                                </div>
                                {fileFormat == "docx" || fileFormat == "doc" || fileFormat == "pdf" ?
                                    <div>
                                        <React.Fragment>
                                            <Editor
                                                apiKey='3f2stkyg60k3jfga1nx8ro6618r792y7fqyut5f44jw8wl3k'
                                                value={htmloriginalDraftref.current}
                                                init={{
                                                    // selector: 'textarea',
                                                    height: "50vh",
                                                    plugins: [
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen powerpaste casechange",
                                                        "directionality advcode visualchars mediaembed codesample pagebreak",
                                                        "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                                                        "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                                                        "insertdatetime media table paste code help wordcount",
                                                    ],
                                                    menubar: false,
                                                    content_style: "body img{ width:100%; }",
                                                    toolbar:
                                                        "undo redo | styleselect | formatselect | bold italic backcolor | \
                                                             alignleft aligncenter alignright alignjustify | \
                                                             bullist numlist outdent indent | removeformat | table tabledelete | \
                                                             tableprops tablerowprops tablecellprops | \
                                                             tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                                             tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                                                }}
                                            />
                                        </React.Fragment>
                                    </div> : <img src={htmloriginalDraft} className="img_cls" />}

                                <div className="button_footer">
                                    <button
                                        onClick={() => actionTab('cancel')} nce
                                        className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div> : ""}
                        {shareAction == true ?
                            <div>
                                <div className="mt-5">
                                    <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white">
                                        Share Marketplace User
                                    </h2>
                                </div>
                                <div>
                                    <form className="with_form_section">
                                        <div class="mb-6">
                                            <label
                                                for="item-name"
                                                class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                                            >
                                                Share Email<span class="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="item-name"
                                                onChange={(e) => setemail(e.target.value)}
                                                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                                                placeholder="User Email"
                                                required
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="button_footer">
                                    <button
                                        onClick={() => actionTab('cancel')}
                                        className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                    >
                                        Cancel
                                    </button>
                                    {loadingbutton == true ? <button className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                    >
                                        Loading...
                                    </button> :
                                        <button onClick={ShareFile} className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                        >
                                            Share
                                        </button>}
                                </div>
                            </div> : ""}
                        {editAction == true ?
                            <div>
                                <div className="mt-5">
                                    <h2 className="mb-10 font-display text-center text-4xl font-medium text-jacarta-700 dark:text-white">
                                        {showfilename}
                                    </h2>
                                </div>
                                <div>
                                    <React.Fragment>
                                        <Editor
                                            apiKey='3f2stkyg60k3jfga1nx8ro6618r792y7fqyut5f44jw8wl3k'
                                            value={htmloriginalDraftref.current}
                                            init={{
                                                height: "50vh",
                                                // selector: 'textarea',
                                                plugins: [
                                                    "advlist autolink lists link image charmap print preview anchor",
                                                    "searchreplace visualblocks code fullscreen powerpaste casechange",
                                                    "directionality advcode visualchars mediaembed codesample pagebreak",
                                                    "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                                                    "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                                                    "insertdatetime media table paste code help wordcount",
                                                ],
                                                menubar: false,
                                                content_style: "body img{ width:100%; }",
                                                toolbar:
                                                    "undo redo | styleselect | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | table tabledelete | \
                                                            tableprops tablerowprops tablecellprops | \
                                                            tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                                                            tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                                            }}
                                            onEditorChange={editTab}
                                        />
                                    </React.Fragment>
                                </div>
                                <div className="button_footer">
                                    <button
                                        onClick={() => actionTab('cancel')}
                                        className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                    >
                                        Cancel
                                    </button>
                                    {draftLoad == true ?
                                        <button
                                            className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                        >
                                            Loading...
                                        </button>
                                        :
                                        <button
                                            onClick={() => SaveEditfile('')}
                                            className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                        >
                                            Save
                                        </button>}
                                    {loading == true ?
                                        <button
                                            nce
                                            className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                        >
                                            Loading...
                                        </button> :
                                        <button
                                            onClick={() => Upload_contact("edit")} nce
                                            className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                                        >
                                            Upload Contact
                                        </button>}
                                </div>

                                {editDraft.map((data, i) => {
                                    const diffCode = generateHighlightedHTML(originalDraftref.current, data.changes);
                                    console.log(diffCode, "====diffCode"); // Debugging log

                                    return (
                                        <div className="card bg-light shadow-sm" key={i}>
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Updated At: {moment(data.date).format("MMMM Do YYYY, h:mm:ss a")}
                                                </h3>
                                                <h3 className="card-title">
                                                    Edited By: {data.email}
                                                </h3>
                                            </div>
                                            <div className="card-body card-scroll h-200px">
                                                <div className="row">
                                                    <div className="col-md-12 new-doc-line">
                                                        <div
                                                            className="text-color"
                                                            dangerouslySetInnerHTML={{ __html: diffCode }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div> : ""}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contract