import React, { useEffect, useState } from "react";
import Header from './adminHeader'
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
function Home() {

    useEffect(() => {
        getUserDetails();
    }, [0])

    const [advocatecount, setadvocatecount] = useState(0)
    const [clientcount, setclientcount] = useState(0)
    const [legalDoccount, setlegalDoccount] = useState(0)
    const [uploadDoccount, setuploadDoccount] = useState(0)

    const getUserDetails = async () => {
        var data = {
            apiUrl: apiService.getUserDetails,
        };
        var resp = await postMethod(data)
        if (resp.status == true) {
            setadvocatecount(resp.data.AdvocateUser)
            setclientcount(resp.data.ClientUser)
            setlegalDoccount(resp.data.LegalDoc)
            setuploadDoccount(resp.data.UploadDoc)
        }
    }

    return (
        <>
            <Header />
            <div className="cantainar container2">
                <div className="dashboard">
                    <div className="card card2">
                        <div className="icon">
                            <i className="fas fa-chart-bar"></i>
                        </div>
                        <div className="details">
                            <h2>Advocate users</h2>
                            <p>{advocatecount}</p>
                        </div>
                    </div>
                    <div className="card card2">
                        <div className="icon">
                            <i className="fas fa-chart-bar"></i>
                        </div>
                        <div className="details">
                            <h2>Client users</h2>
                            <p>{clientcount}</p>
                        </div>
                    </div>
                    <div className="card card2">
                        <div className="icon">
                            <i className="fas fa-file-alt"></i>
                        </div>
                        <div className="details">
                            <h2>Legal Documents</h2>
                            <p>{legalDoccount}</p>
                        </div>
                    </div>
                    <div className="card card2">
                        <div className="icon">
                            <i className="fas fa-file-upload"></i>
                        </div>
                        <div className="details">
                            <h2>Uploaded documents</h2>
                            <p>{uploadDoccount}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home