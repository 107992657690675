import React from "react";

function TabContent({activeTab}) {
  const renderTable = () => {
    switch (activeTab) {
      case "Inbox":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Inbox</h5>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Albert Flores</td>
                  <td>
                    <span className="status-icon">✖</span> Need to sign
                  </td>
                  <td>
                    <div>08/08/24</div>
                    <div className="subtext">12:11:36 am</div>
                  </td>
                  <td>
                    <button className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                      Sign
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case "Sent":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Sent</h5>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Signed</td>
                  <td>08/07/24</td>
                  <td>
                    <button className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case "Completed":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Completed</h5>

            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jane Smith</td>
                  <td>Completed</td>
                  <td>08/06/24</td>
                  <td>
                    <button className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case "ActionRequired":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Action Required</h5>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mary Johnson</td>
                  <td>Pending</td>
                  <td>08/05/24</td>
                  <td>
                    <button className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                      Review
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      default:
        return null;
    }
  };

  return <div className="tab-content">{renderTable()}</div>;
}

export default TabContent;
