import React from "react";
import {Link} from "react-router-dom";
import DesktopSidebar from "./componants/digisign/DesktopSidebar";
import MobileBottomNav from "./componants/digisign/MobileBottomNav";
import useWindowSize from "./componants/digisign/useWindowSize";
import  Headers from "../../pages/Header";
function NewLayout() {
  const {width} = useWindowSize();
  return (
    <>
    <Headers/>
    <div className="flex min-h-screen bg-[#0D0B35]">
      {/* Sidebar */}
      {width >= 768 ? <DesktopSidebar /> : <MobileBottomNav />}

      {/* Main Content */}
      <main className="ml-64 w-full bg-[#0D0B35] p-8 side_content">
        <div className="container mx-auto h-[100vh] overflow-y-auto bg-[#10153D] rounded-lg p-6">
          <img
            src={require("../../img/newbannar/newbannar/text-editor.png")}
            alt="gradient"
            class="w-full"
          />
        </div>
      </main>
      <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4 footer_respon">
        <div className="container-lg mx-auto flex justify-end">
          <Link
            className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            to="/next-step"
          >
            Done
          </Link>
        </div>
      </footer>
      <div
        class="modal fade"
        id="upload"
        tabindex="-1"
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered max-w-lg justify-center">
          <div class="modal-content max-w-lg width_modlew_60">
            <div class="modal-header model_padding">
              <h5 class="modal-title" id="walletModalLabel">
                Upload Your Sign
              </h5>
              <button
                type="button"
                class="btn-close flex_close_button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <div class="modal-body p-6 text-center">
              <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                <label
                  htmlFor="file-upload"
                  className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center module_filw"
                >
                  <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                  <span className="text-white text-lg font-medium">
                    Upload Document
                  </span>
                  <input id="file-upload" type="file" className="hidden" />
                </label>
              </div>
            </div>

            <div class="modal-footer flex items-end justify-end py-10 model_padding">
              <div class="flex items-end justify-end space-x-4 flex_end_sner">
                <a
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Upload
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="Draw"
        tabindex="-1"
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered max-w-lg justify-center">
          <div class="modal-content max-w-lg width_modlew_60">
            <div class="modal-header model_padding">
              <h5 class="modal-title" id="walletModalLabel">
                Draw Your Sign
              </h5>
              <button
                type="button"
                class="btn-close flex_close_button px-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <div class="modal-body p-6 text-center">
              <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                <label className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center module_filw"></label>
              </div>
            </div>

            <div class="modal-footer flex items-end justify-end py-10 model_padding">
              <div class="flex items-end justify-end space-x-4 flex_end_sner">
                <a
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Sign
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="TextSign"
        tabindex="-1"
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered max-w-lg justify-center">
          <div class="modal-content max-w-lg width_modlew_60">
            <div class="modal-header model_padding">
              <h5 class="modal-title" id="walletModalLabel">
                Enter Your Sign
              </h5>
              <button
                type="button"
                class="btn-close flex_close_button px-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <div class="modal-body p-6 text-center">
              <div className="mb-6">
                <label
                  htmlFor="item-name"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                >
                  Name<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="item-name"
                  name="Enter Name"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  placeholder="Enter Name"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="item-name"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                >
                  Fontfamily<span className="text-red">*</span>
                </label>
                <select
                  class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                  id="cars"
                  name="Country"
                >
                  <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                    Fontfamily
                  </option>
                </select>
              </div>
            </div>

            <div class="modal-footer flex items-end justify-end py-10 model_padding">
              <div class="flex items-end justify-end space-x-4 flex_end_sner">
                <a
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Sign
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NewLayout;
