import React, { useEffect, useState } from "react";
import Header from "./Header"
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import { useNavigate } from "react-router-dom";
function Mediator() {

    const navigate = useNavigate()
    useEffect(() => {
        getsharelist();
    }, [])
    const [MediatorList, setMediatorList] = useState([])
    const [loader, setloader] = useState(false)
    const getsharelist = async () => {
        var data = {
            apiUrl: apiService.getadvocateList,
        };
        setloader(true)
        var resp = await postMethod(data)
        setloader(false)
        if (resp.status == true) {
            setMediatorList(resp.Mediator == undefined ? [] : resp.Mediator)
        }
    }
    const goToProfile = async (id) => {
        try {
            navigate("/profile?" + id);
        } catch (error) {
        }
    };
    return (
        <>
            <Header />
            <section class="relative py-24 dark:bg-jacarta-800">
                <div class="container">
                    <div class="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
                        <h2 class="inline"> Mediator Panel </h2>
                    </div>
                    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
                        {MediatorList.map((data, i) => {
                            return (
                                <div class="card_layer posiuion">
                                    {data.prfileIMG == "" ? <><div class="lawer_imga"><img src="/static/media/advocateImage-removebg-preview.1aaf7ecc5a19afb2064f.png"
                                        width="100px" height="100px" alt="avatar 1" class="rounded-2lg" loading="lazy" /></div></> : <><div class="lawer_imga"><img src={data.prfileIMG}
                                            width="100px" height="100px" alt="avatar 1" class="rounded-2lg" loading="lazy" /></div></>}
                                    <div class="lawer_info">
                                        <h4>{data.fullName}</h4>
                                        <p><span>Experience:</span> {data.Experience}</p>
                                        <p><span>Expertise:</span> {data.exertise}</p>
                                        <p><i class="ri-map-pin-fill"></i>{data.country}</p>
                                    </div>
                                    <div className="postion_hover">
                                        {" "}
                                        <a onClick={() => goToProfile(data._id)}>View</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {loader == true ?
                        <div class="flex_loading">
                            <img
                                width="60"
                                height="60"
                                class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                                src={require("../img/loadinggif.gif")}
                            ></img>
                        </div> :
                        MediatorList.length > 0 ? "" :
                            <div class="mt-10 text-center"><a
                                class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">No data found</a></div>}
                </div>
            </section>
        </>
    );
}
export default Mediator;