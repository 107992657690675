import { React, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Colleaction from "./pages/Colleaction";
import ItemsDetails from "./pages/ItemsDetails";
import Profile from "./pages/Profile";
import Create from "./pages/Create";
import Login from "./pages/Login";
import Client from "./pages/Client";
import Advacate from "./pages/Advacate";
import Editprofile from "./pages/Editprofile";
import Lawermore from "./pages/Lawermore";
import Checkout from "./pages/Checkout";
import Commingsoon from "./pages/Commingsoon";
import Editpdf from "./pages/Editpdf";
import Digitalsign from "./pages/Digitalsign";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAuthToken } from "./core/lib/localStorage";
import ClientProfile from "./pages/cleintProfile";
import ClientEdit from "./pages/clientEditProfile";
import TemplateMore from "./pages/templateMore";
import TemplateMoreFiter from "./pages/TemplateMoreFiter";
import AIchatPage from "./pages/AIchatPage";
import ForgotPassword from "./pages/ForgotPassword";
import Aicreatetemp from "./pages/Aicreatetemp";
import Contract from "./pages/contract";
import Arbitrator from "./pages/Arbitrator";
import Mediator from "./pages/Mediator";
import Admindashboard from './admin/adminDashboard'
import Contactus from "./admin/Contactus";
import Bloglist from "./admin/bloglist";
import Blogupload from "./admin/blogupload";
import documentDisplay from './signComponent/DocumentDisplay'
import DigitalDash from "../src/digital_sign/digital_sign/DigitalDashboard"
import DigitalUpload from "../src/digital_sign/digital_sign/DigitalUpload";
import DigitalSign2 from "../src/digital_sign/digital_sign/DigitalSign";
import Digitaldetails from "../src/digital_sign/digital_sign/DigitalDetails";


function App() {
  /*Authemticatio Guard */

  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      <>
        <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/colleaction" element={<Colleaction />} /> */}
            <Route
              path="/docusign"
              element={
                <RequireAuth>
                  {" "}
                  <documentDisplay />{" "}
                </RequireAuth>
              }
            />            <Route
              path="/Item"
              element={
                <RequireAuth>
                  {" "}
                  <ItemsDetails />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  {" "}
                  <Profile />{" "}
                </RequireAuth>
              }
            />
            <Route path="/profile/:id" element={<Profile />} />
            <Route
              path="/create"
              element={
                <RequireAuth>
                  {" "}
                  <Create />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/digitalSign"
              element={
                <RequireAuth>
                  {" "}
                  <Digitalsign />{" "}
                </RequireAuth>
              }
            />

            <Route path="/digitalDash" element={<DigitalDash />} />
            <Route path="/digitalupload" element={<DigitalUpload />} />
            <Route path="/digital_sign" element={<DigitalSign2 />} />
            <Route path="/Digitaldetails" element={<Digitaldetails />} />


            <Route path="/login" element={<Login />} />
            <Route path="/clientregister" element={<Client />} />
            <Route path="/advacateclient" element={<Advacate />} />
            <Route
              path="/create-legal-template-ai"
              element={
                <RequireAuth>
                  {" "}
                  <Aicreatetemp />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/upload-legal-contract-blockchain"
              element={
                <RequireAuth>
                  {" "}
                  <Contract />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/legal-chat-ai"
              element={
                <RequireAuth>
                  {" "}
                  <AIchatPage />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/editprofile"
              element={
                <RequireAuth>
                  {" "}
                  <Editprofile />{" "}
                </RequireAuth>
              }
            />
            <Route path="/lawermore" element={<Lawermore />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  {" "}
                  <Checkout />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/clientProfilers"
              element={
                <RequireAuth>
                  {" "}
                  <ClientProfile />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/editpdf"
              element={
                <RequireAuth>
                  {" "}
                  <Editpdf />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/ClientEditProfile"
              element={
                <RequireAuth>
                  {" "}
                  <ClientEdit />{" "}
                </RequireAuth>
              }
            />

            <Route path="/commingsoon" element={<Commingsoon />} />
            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  {" "}
                  <Checkout />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/clientProfilers"
              element={
                <RequireAuth>
                  {" "}
                  <ClientProfile />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/editpdf"
              element={
                <RequireAuth>
                  {" "}
                  <Editpdf />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/digital-sign"
              element={
                <RequireAuth>
                  {" "}
                  <Digitalsign />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <RequireAuth>
                  {" "}
                  <Admindashboard />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/admin/contactus"
              element={
                <RequireAuth>
                  {" "}
                  <Contactus />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/admin/blogs"
              element={
                <RequireAuth>
                  {" "}
                  <Bloglist />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/admin/blogupload"
              element={
                <RequireAuth>
                  {" "}
                  <Blogupload />{" "}
                </RequireAuth>
              }
            />

            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  {" "}
                  <Checkout />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/arbitrator"
              element={
                <RequireAuth>
                  {" "}
                  <Arbitrator />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/mediator"
              element={
                <RequireAuth>
                  {" "}
                  <Mediator />{" "}
                </RequireAuth>
              }
            />
            <Route
              path="/clientProfilers"
              element={
                <RequireAuth>
                  {" "}
                  <ClientProfile />{" "}
                </RequireAuth>
              }
            />
            <Route path="/alltemplates" element={<TemplateMore />} />
            <Route path="/purchase-legal-document" element={<TemplateMoreFiter />} />
          </Routes>
        </BrowserRouter>
      </>
    </>
  );
}

export default App;
